import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Button = makeShortcode("Button");
const Callout = makeShortcode("Callout");
const Tout = makeShortcode("Tout");
const Link = makeShortcode("Link");
const List = makeShortcode("List");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Governance and Risk Meetings`}</h1>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/governance-meeting-call-8-20-2020.png",
          "alt": "G&R Meetings"
        }}></img></p>
      <Box mdxType="Box">
        <h3>{`Discuss Ideas and Get Informed`}</h3>
      </Box>
    </InfoBlock>
    <h2>{`When?`}</h2>
    <p>{`Governance and risk meetings take place `}<strong parentName="p">{`Every Thursday`}</strong>{` at 4:00 PM UTC.
You are welcome to join!`}</p>
    <Button to="https://zoom.us/j/697074715" mdxType="Button"> Join the Call! </Button>
    <Callout icon="warning" mdxType="Callout">
      <p>{`Public meetings are now password-protected to prevent spam. The weekly password is on the `}<a parentName="p" {...{
          "href": "https://forum.makerdao.com/c/governance/gnr"
        }}>{`forum.`}</a></p>
    </Callout>
    <h2>{`What’s on the Menu?`}</h2>
    <p>{`These meetings set the stage for cultural and operational consensus across the community for a broad range of stakeholders and issues related to these `}<a parentName="p" {...{
        "href": "https://community-development.makerdao.com/governance/common-topics"
      }}>{`frequent discussion topics.`}</a></p>
    <p>{`Governance Facilitators host the calls and put together the agenda each week. Below are typical inclusions:`}</p>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h3>{`Updates`}</h3>
        <ul>
          <li parentName="ul">{`Weekly MIP Updates`}</li>
          <li parentName="ul">{`Weekly Domain Team Updates`}</li>
          <li parentName="ul">{`Working Group Updates As Needed`}</li>
          <li parentName="ul">{`Collateral Onboarding Updates As Needed`}</li>
        </ul>
      </Box>
      <Box mdxType="Box">
        <h3>{`Procedural Matters`}</h3>
        <ul>
          <li parentName="ul">{`Monthly MIP Submission Reviews`}</li>
          <li parentName="ul">{`Monthly Governance Cycle Reviews`}</li>
          <li parentName="ul">{`Monthly Inclusion Poll Reviews`}</li>
          <li parentName="ul">{`Monthly Governance Poll Reviews`}</li>
        </ul>
      </Box>
    </Tout>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h3>{`Presentations`}</h3>
        <ul>
          <li parentName="ul">{`MIP and Subproposal Overviews`}</li>
          <li parentName="ul">{`Technical Overviews`}</li>
          <li parentName="ul">{`Working Group Project Overviews`}</li>
          <li parentName="ul">{`Longer, More Detailed Team Updates`}</li>
          <li parentName="ul">{`State of the Peg`}</li>
          <li parentName="ul">{`Deep Dives into other `}<a parentName="li" {...{
              "href": "https://community-development.makerdao.com/governance/common-topics"
            }}>{`Frequent Topics`}</a></li>
        </ul>
      </Box>
      <Box mdxType="Box">
        <h3>{`Discussion`}</h3>
        <ul>
          <li parentName="ul">{`Comments and Questions are encouraged throughout the calls`}</li>
          <li parentName="ul">{`MIP-focused discussion sessions`}</li>
          <li parentName="ul">{`Other `}<a parentName="li" {...{
              "href": "https://community-development.makerdao.com/governance/common-topics"
            }}>{`Frequent Topic`}</a>{` discussion sessions`}</li>
          <li parentName="ul">{`Open-ended discussion sessions at the end of some calls`}</li>
        </ul>
      </Box>
    </Tout>
    <h2>{`Earn Dai by Taking Notes`}</h2>
    <p>{`Notes are a primary  way to document discussions and decisions. They make the content more accessible to the people who can't make the meetings or don't have time to watch the full recordings.`}</p>
    <Link to="/contribute/governance_and_risk_meetings/take-notes/" mdxType="Link">Join our Summary Team, and we’ll pay you!</Link>
    <h2>{`Past Meeting Archives`}</h2>
    <List mdxType="List">
      <Link to="https://community-development.makerdao.com/governance/governance-and-risk-meetings/summaries" mdxType="Link"> **Summaries** Read summaries of past meetings </Link>
      <Link to="https://community-development.makerdao.com/" mdxType="Link"> **Frequent Topics** See what's discussed in meetings </Link>
      <Link to="https://community-development.makerdao.com/governance/common-topics" mdxType="Link"> **Audio Recordings** Listen to past meetings </Link>
      <Link to="https://www.youtube.com/playlist?list=PLLzkWCj8ywWNq5-90-Id6VPSsrk4OWVan" mdxType="Link"> **Video Recordings** View past meetings </Link>
    </List>
    <Column mdxType="Column">
      <Box mdxType="Box">
        <h3>{`Learn About Governance`}</h3>
        <p>{`We’ve created lots of content to help better understand governance and show you ways to get involved.`}</p>
        <p><a parentName="p" {...{
            "href": "/learn/governance/"
          }}>{`Learn About Governance`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Visit the Voting Portal`}</h3>
        <p>{`See where the voting happens and take a look at the current votes.`}</p>
        <p><a parentName="p" {...{
            "href": "https://vote.makerdao.com/"
          }}>{`Voting Portal`}</a></p>
      </Box>
    </Column>
    <Callout icon="question_o" mdxType="Callout">
      <p>{`If you have any questions, feel free to reach out to us on `}<Link to={"https://chat.makerdao.com/channel/community-development"} icon={"rocketchat"} mdxType="Link">{`Community Chat.`}</Link></p>
    </Callout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      